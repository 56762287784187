import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import Image from 'gatsby-image';

import Layout from '../components/common/layout';
import Hero from '../components/common/hero';

const TextWrapper = styled.div`
  text-align: justify;
  p:not(:first-child) {
    margin-top: 1rem;
  }
`;

const StyledCronoDesktop = styled(Image)`
  max-width: 800px;
  height: auto;
  margin: 3rem auto 1rem;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledCronoMobile = styled(Image)`
  max-width: 800px;
  height: auto;
  margin: 1rem 0;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const About = () => {
  const { cronoDesktop, cronoMobile } = useStaticQuery(graphql`
    query {
      cronoDesktop: file(relativePath: { eq: "crono_desktop.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cronoMobile: file(relativePath: { eq: "crono_mobile.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Hero isBig={false}>
        <h1 style={{ color: '#ffffff', paddingRight: '60px' }}>Sobre Nós</h1>
      </Hero>
      <div className="content">
        <div>
          <StyledCronoDesktop fluid={cronoDesktop.sharp.fluid} />
          <StyledCronoMobile fluid={cronoMobile.sharp.fluid} />
        </div>
        <TextWrapper>
          <p>
            A <b>GHCP</b> foi <b>fundada em janeiro de 2007</b>, como empresa de
            serviços vocacionada na área de projetos de especialidades
            (Elétrico, ITED, SCRI, Gás, etc..).
          </p>
          <p>
            Em <b>2008</b> foi acreditada pela ANACOM como
            <b> entidade certificadora ITED</b>, o que a habilitou a realizar
            ensaios ITED, de acordo com as especificações definidas pela ANACOM
            e legislação em vigor. Com a entrada em vigor do DL 123/2009 em
            22/05/2009 que extinguiu as entidades certificadoras ITED, a GHCP
            continuou a dar apoio aos instaladores ITED na realização dos
            ensaios, que atualmente já não faz parte da nossa atividade.
          </p>
          <p>
            A elaboração de{' '}
            <b>projetos elétricos, ITED e segurança contra risco de incêndio</b>{' '}
            continuaram a ser a principal área de atuação da empresa até 2017.
          </p>
          <p>
            Em <b>2017</b> passou a dedicar-se predominantemente aos projetos de
            fibra ótica (FTTH) nas redes das operadoras de telecomunicações de
            Portugal.
          </p>
          <p>
            Em <b>2019</b> alargou o âmbito de atuação na área de{' '}
            <b>projetos FTTH a França</b>.
          </p>
        </TextWrapper>
      </div>
    </Layout>
  );
};

export default About;
